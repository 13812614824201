//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

//import { mapState, mapActions } from "vuex";


//import TSLoader from "@/components/ts_loader";

export default {
  data: function () {
    return {
      email:'',
      show:false,
      menu_app: []
    }
  },
  //components: {
  //  TSLoader
  //},
  created() {

    console.log('create -> [Main]');
    //let _token_ = localStorage.getItem('_token_');
    //console.log(_token_);
    //localStorage.setItem('_token_', 'xx');
    let _token_ = localStorage.getItem('_token_');
    this.email = localStorage.getItem('_email_');
    console.log('_token_ : '+_token_);

    //let localStorage.setItem('key', 'value');

    //console.log(this.$store.state.my_user); 

    // No esta myUser, me voy a login
    //if (this.$util.isNull(this.$store.state.my_user)) {
    //
    //  this.$router.push('/login');
    //}
    if (this.$util.isNull(_token_))  {
       this.$router.push('/login');
    }
    this.$axios.defaults.headers.common['Authorization'] = "Bearer "+_token_;        
    
    this.menu_app = [
      { id:0, icon: 'mdi-tanker-truck', name: 'Camiones', url:"/main/camiones_paneles" },
      //{ id:1, icon: 'mdi-calendar-search', name: 'Eventos', url:"/main/eventos" },
      { id:4, icon: 'mdi-file-chart', name: 'Reportes', url:"/main/reportes" },
      { id:3, icon: 'mdi-account', name: 'Usuarios', url:"/main/usuarios" },
      //{ id:3, icon: 'mdi-wrench-outline', name: 'Configuración', url:"/main/configuracion" },
    ];
    this.show = true;

  },
  computed: {

  },
  methods: {
    click_item_app: function(item) {
      if (this.$store.state.ts.loader.show) return;
      console.log(item);
      this.$router.push(item.url);
    },
    
    onClickLogout: function() {
      if (this.$store.state.ts.loader.show) return;
     
      localStorage.setItem('_token_', null);

      this.$store.state.my_user=null;
      this.$axios.defaults.headers.common['Authorization'] = "Bearer x";
      this.$router.push("/login"); 
    }
  }
};
